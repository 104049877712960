import { useTexture } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { useMemo, useState } from "react";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { useSpring, animated, config } from "@react-spring/three";

const Planet = ({
  objURL = "Earth.obj",
  textUrl = "https://raw.githubusercontent.com/Hoang21099/mar-asset/master/planet_lava_Base_Color.jpg",
  scaleA = 0.5,
  position,
  wireframe,
  starParams,
  textUrlA,
  textUrlB,
  opacity = 1,
  side=THREE.BackSide
}) => {
  const { scale } = useSpring({
    scale: scaleA,
    config: { tension: 180, friction: 12 },
    delay: 700,
  });

  const obj = useLoader(OBJLoader, objURL);
  console.log(obj);
  const texture = useTexture(textUrl);
  // const textureA = useTexture(textUrlA);
  // const textureB = useTexture(textUrlB);

  // const textures = useTexture([textUrl, textUrlA, textUrlB]);

  const geometry = useMemo(() => {
    let g;
    obj.traverse((c) => {
      if (c.type === "Mesh") {
        const _c = c;
        g = _c.geometry;
      }
    });
    return g;
  }, [obj]);

  return (
    <>
      <animated.mesh geometry={geometry} scale={scale} position={position}>
        <meshPhysicalMaterial
          polygonOffset={true}
          polygonOffsetFactor={-4}
          depthWrite={false}
          depthTest={false}
          transparent
          map={texture}
          opacity={opacity}
          side={side}
        />
      </animated.mesh>
    </>
  );
};

export default Planet;
