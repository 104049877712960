import React, { useEffect, useState, Suspense } from "react";
import { Canvas} from "@react-three/fiber";
// import { Physics } from "@react-three/cannon";
// import { OrbitControls, Stars, Sky } from "@react-three/drei";
import { OrbitControls, PerspectiveCamera, Stars } from '@react-three/drei'
import { useSearchParams } from "react-router-dom";
// import { Suspense } from "react";
// import Cloud from "./components/cloud";
import Planet from "./components/planet";
import * as THREE from "three";
import { Spin } from "react-cssfx-loading";

import "./Star.css";
const axios = require("axios").default;

const Star = () => {
  let [searchParams] = useSearchParams();
  let starParam = searchParams.get("star");
  const [texture, setTexture] = useState(
    ""
  );

  useEffect(() => {
    if (starParam) {
      axios
        .get(`https://api-prod.milkywaygalaxy.io/api/public/star/${starParam}`)
        .then(function (response) {
          // handle success
          if (response.data.success && response.data?.data.img3dUrl) {
            setTexture(response.data.data.img3dUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [starParam]);

  const [scale, setScale] = React.useState(0.5);

  useEffect(() => {
    // alert("haha");

    // IOS Working
    window.addEventListener("message", (message) => {
      // alert(JSON.stringify(message.data));
      try {
        processEventData(message);
      } catch (error) {
        console.log("Err window");
        // alert(error.message);
      }
    });
    //Android Working
    document.addEventListener("message", (message) => {
      try {
        processEventData(message);
      } catch (error) {
        console.log("Err document");
      }
    });
  }, []);

  const processEventData = (mess) => {
    // alert(JSON.stringify(JSON.parse(mess)?.data));
    const data = JSON.parse(mess.data) || {};
    if (data?.type === "ZoomIn") {
      // alert("ZoomIn");
      setScale(0.6);
    } else if (data?.type === "ZoomOut") {
      // alert("ZoomOut");
      setScale(0.5);
    }
  };

  return (

  //   <>
  //   <div className="bg" />
  //   <Canvas dpr={[1.5, 2]} linear shadows>
  //   <fog attach="fog" args={['#272730', 16, 30]} />
  //   <ambientLight intensity={0.75} />
  //   <PerspectiveCamera makeDefault position={[0, 0, 0]} fov={75}>
  //     <pointLight intensity={1} position={[0, 0, 0]} />
  //     <spotLight castShadow intensity={2.25} angle={0.8} penumbra={1} position={[0, 0, 0]} shadow-mapSize={[1024, 1024]} shadow-bias={-0.0001} />
  //   </PerspectiveCamera>
  //   <Suspense fallback={null}>
  //          {!!texture && <Planet
  //           scaleA={scale}
  //           position={[0, 0, 0]}
  //           starParam={starParam}
  //           textUrl={texture}
  //           side={THREE.FrontSide}/>}
  //   </Suspense>
  //   <OrbitControls autoRotate enablePan={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2}  minDistance={1}  maxDistance={2.5}/>
  //   <Stars radius={500} depth={50} count={1000} factor={10} />
  // </Canvas>
  //   <div className="layer" />
  //   {/* <Loader /> */}
  // </>

   
<>
<div className="bg" />
    <Canvas shadowMap gl={{ antialias: false }}>
      {/* <OrbitControls minDistance={4.5} maxDistance={6} enablePan={false}/> */}
      <OrbitControls
        minDistance={1}
        maxDistance={2.5}
        enablePan={false}
        // enableRotate={false}
        // position={[0, 1, 0]}
      />
      <Stars
        radius={100}
        depth={50}
        count={4000}
        factor={7}
        saturation={1}
        speed={8}
      />

      {/* <spotLight intensity={1} position={[300, 300, 4000]} angle={0.2} penumbra={1} castShadow/> */}

      {/* <ambientLight intensity={1} /> */}
      {/* <ambientLight intensity={1} /> */}
      {/* <pointLight position={[10, 10, 10]} castShadow /> */}
      {/* <spotLight intensity={0.8} position={[0, 0, 0]} /> */}
      {/* <primitive object={new THREE.AxesHelper(10)} /> */}

         <PerspectiveCamera makeDefault position={[0, 0, 10]} fov={75}>
       <pointLight intensity={1} position={[0, 0, 10]} />
       <spotLight castShadow intensity={2.25} angle={0.8} penumbra={1} position={[0, 0, 10]} shadow-mapSize={[1024, 1024]} shadow-bias={-0.0001} />
     </PerspectiveCamera>
     <Suspense fallback={null}>
     {!!texture && <Planet
            scaleA={scale}
            position={[0, 0, 0]}
            starParam={starParam}
            textUrl={texture}
            side={THREE.FrontSide}
          />}
     </Suspense>
    

      {/* <Physics> */}
        {/* <Float
          speed={1} // Animation speed, defaults to 1
          rotationIntensity={2} // XYZ rotation intensity, defaults to 1
          floatIntensity={0} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
          floatingRange={[1, 100]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        > */}
        <>
          {/* {!!texture && <Planet
            scaleA={scale}
            position={[0, 0, 0]}
            starParam={starParam}
            textUrl={texture}
            side={THREE.FrontSide}

            // textUrl={`https://raw.githubusercontent.com/Hoang21099/mar-asset/master/${LINK_TEXTURE[starParam]}`}
          />} */}

          {/* <Planet
            scaleA={scale}
            position={[0, 0, 0]}
            opacity={0.6}
            side={THREE.FrontSide}

            starParam={starParam}
            textUrl={`https://raw.githubusercontent.com/Hoang21099/mar-asset/master/earthA.png`}
            

            // textUrl={`https://raw.githubusercontent.com/Hoang21099/mar-asset/master/${LINK_TEXTURE[starParam]}`}
          /> */}

          {/* <Planet
            scaleA={scale}
            position={[0, 0, 0]}
            opacity={0.2}
            side={THREE.FrontSide}
            starParam={starParam}
            textUrl={`https://raw.githubusercontent.com/Hoang21099/mar-asset/master/earthC.jpeg`}
            

            // textUrl={`https://raw.githubusercontent.com/Hoang21099/mar-asset/master/${LINK_TEXTURE[starParam]}`}
          /> */}
        </>
        {/* </Float> */}
      {/* </Physics> */}
    </Canvas>
    <div className="layer" />
    </>
  );
};

export default Star;
